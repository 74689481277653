import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { find, noop } from 'lodash';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormControl, IconButton, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// @ts-ignore
import { ITradeIdea, PriceTarget, StrategyType, TradeIdeaModel } from '@ystb/backend/dist/tradeIdea/tradeIdeaModel';
import AppState from '../AppState';
// @ts-ignore
import { getPercentStr } from '@ystb/backend/dist/utils/price';
// @ts-ignore
import { FiboModel } from '@ystb/backend/dist/fibo/fiboModel';
// @ts-ignore
import { IdeaType, IStrategyItems, TimeFrame, TrendDirection } from '@ystb/backend/dist/common';

import { InstrumentsAutocomplete, SelectTickerOption } from '../components/InstrumentsAutocomplete';
import { getFormattedDate, getTimeframe } from '../utils/common';
import { isLongIdea, TradeIdeaValidationResponse, validateTradeIdea } from '../utils/tradeIdea.utils';
import { ConfirmationDialog, IDialogAction } from './components/ConfirmationDialog';

export interface ITradeIdeaView {
    id: string;
    ticker: string;
    buyPrice: number;
    timeFrame: TimeFrame;
    strategyType: StrategyType;
    createDate: Date;
    isActive: boolean;
    stopLose: PriceTarget;
    target1percent: PriceTarget;
    target1000: PriceTarget;
    target1618: PriceTarget;
    target2618: PriceTarget;
    target3618: PriceTarget;
    target4236: PriceTarget;
    lastPrice: number;
    moexLevel: number;
    ideaType: IdeaType;
}

enum ActiveStatus {
    None = '',
    Active = '1',
    InActive = '2',
}

enum IdeaTypeItems {
    All = 'All',
    Short = 'short',
    Long = 'long',
}

let isActivated = false;

export function TradeIdeasTable() {
    const [activeStatus, setActiveStatus] = useState(ActiveStatus.None);
    const [timeFrame, setTimeFrame] = useState('');
    const [ideaType, setIdeaType] = useState<string>(IdeaTypeItems.Long);
    const [strategyType, setStrategyType] = useState<IStrategyItems>(IStrategyItems.All);
    const [moexLevel, setMoexLevel] = useState<string>('');
    const [items, setItems] = useState<ITradeIdeaView[]>([]);
    const [fiboModels, setFiboModels] = useState<FiboModel[]>([]);
    const [ticker, setTicker] = useState<SelectTickerOption>();
    const [action, setAction] = useState<IDialogAction | null>(null);

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        AppState.restClient.fetchTradeIdeas().then(async (data) => {
            const models = data.map((rawModel: ITradeIdea) => {
                const model = new TradeIdeaModel(rawModel);
                const instrument = find(AppState.instruments, ({ ticker }) => ticker === model.ticker);
                const lastPrice = instrument?.lastPrice || 0;
                const moexLevel = instrument?.moexLevel || 0;
                return Object.assign({}, model, { lastPrice, moexLevel });
            });
            setItems(models);
        });
        AppState.restClient.fetchFibo().then(async (data) => {
            const models = data.filter((item) => !!item.update_date).map((rawModel) => new FiboModel(rawModel));
            setFiboModels(models);
        });
    }, []);

    const filterItemsToRender = useCallback(
        (item: ITradeIdeaView) => {
            let filterByFibo = true;
            let filterTicker = true;
            let filterActive = true;
            let filterMoexLevel = true;
            let filterIdeaType = true;
            let filterStrategyType = true;
            if (timeFrame) {
                filterByFibo = item.timeFrame === timeFrame;
            }
            if (ticker) {
                filterTicker = item.ticker === ticker.ticker;
            }
            if (activeStatus) {
                if (activeStatus === ActiveStatus.Active) {
                    filterActive = item.isActive;
                } else if (activeStatus === ActiveStatus.InActive) {
                    filterActive = !item.isActive;
                }
            }
            if (moexLevel) {
                filterMoexLevel = item.moexLevel.toString() === moexLevel;
            }
            if (ideaType !== IdeaTypeItems.All) {
                filterIdeaType = item.ideaType === ideaType;
            }
            if (strategyType !== IStrategyItems.All) {
                // @ts-ignore
                filterStrategyType = item.strategyType === strategyType;
            }

            return filterActive && filterByFibo && filterTicker && filterMoexLevel && filterIdeaType && filterStrategyType;
        },
        [timeFrame, ticker, activeStatus, moexLevel, ideaType, strategyType]
    );

    const handleCloseModal = useCallback(() => {
        setAction(null);
    }, [setAction]);

    const handleDeactivateIdea = useCallback(
        (tradeIdea: TradeIdeaModel) => {
            const ideaDate = dayjs(tradeIdea.createDate).format('DD-MM-YYYY HH:mm');
            setAction({
                title: `Деактивировать идею #${tradeIdea.ticker} - ${tradeIdea.ideaType} от ${ideaDate}`,
                onSubmit: async () => {
                    await AppState.restClient.deactivateTradeIdea(tradeIdea);
                    window.location.reload();
                },
            });
        },
        [setAction]
    );

    const handleTimeFrameChange = (event: SelectChangeEvent) => {
        setTimeFrame(event.target.value);
    };

    const handleActiveStatusChange = (event: SelectChangeEvent) => {
        setActiveStatus(event.target.value as ActiveStatus);
    };

    const handleMoexLevelChange = (event: SelectChangeEvent) => {
        setMoexLevel(event.target.value);
    };

    const handleTrendDirectionChange = (event: SelectChangeEvent) => {
        setIdeaType(event.target.value as IdeaTypeItems);
    };

    const handleStrategyChange = (event: SelectChangeEvent) => {
        setStrategyType(event.target.value as IStrategyItems);
    };

    const handleTickerChange = (event: SyntheticEvent, value: any) => {
        setTicker(value || '');
    };

    const columns: GridColDef[] = [
        {
            field: 'isActive',
            headerName: '',
            width: 40,
            renderCell: (params) => {
                const { isActive }: TradeIdeaModel = params.row;
                return isActive ? <div /> : <CheckCircleOutlineIcon />;
            },
        },
        {
            field: 'ticker',
            headerName: 'Тикер',
            width: 130,
            renderCell: (params) => {
                const tradeIdea: TradeIdeaModel = params.row;
                const trend = isLongIdea(tradeIdea) ? TrendDirection.Up : TrendDirection.Down;

                let fiboModel = fiboModels.find((item) => {
                    return (
                        item.ticker === tradeIdea.ticker &&
                        item.trendDirection === trend &&
                        item.timeFrame === tradeIdea.timeFrame
                    );
                });
                let warningAlert = '';
                let warningColor: 'warning' | 'error' | 'primary' = 'warning';

                if (tradeIdea.isActive) {
                    const validationResult = validateTradeIdea(tradeIdea, fiboModel);

                    switch (validationResult.type) {
                        case TradeIdeaValidationResponse.FiboDoesNotExist:
                            warningAlert = 'Fibo не существует';
                            warningColor = 'error';
                            break;
                        case TradeIdeaValidationResponse.TargetDoesNotMatchFibo:
                            const percent = validationResult.percent as number;
                            fiboModel = fiboModel as FiboModel;
                            warningAlert = `Цель больше сетки. Idea: ${
                                tradeIdea.target4236.price
                            } vs Fibo: ${fiboModel.fib4236.toFixed(2)} | Idea date: ${dayjs(
                                tradeIdea.createDate
                            ).format('YYYY-MM-DD (HH:mm)')} vs Fibo date ${dayjs(fiboModel.updateDate).format(
                                'YYYY-MM-DD (HH:mm)'
                            )}`;
                            if (percent > 10) {
                                warningColor = 'warning';
                            } else {
                                warningColor = 'primary';
                            }
                            break;
                    }
                }

                return (
                    <>
                        {!!warningAlert && (
                            <Tooltip title={warningAlert}>
                                <IconButton
                                    aria-label="stop"
                                    size={'small'}
                                    onClick={() => {
                                        handleDeactivateIdea(tradeIdea);
                                    }}
                                >
                                    <WarningIcon color={warningColor} />
                                </IconButton>
                            </Tooltip>
                        )}
                        &nbsp;
                        <Link underline={'none'} href={`/#/dashboard/instrument/${tradeIdea.ticker}`}>
                            #{tradeIdea.ticker}
                        </Link>
                        &nbsp;({getTimeframe(tradeIdea.timeFrame)})
                    </>
                );
            },
        },
        {
            field: 'ideaType',
            headerName: 'Тип',
            width: 80,
        },
        {
            field: 'strategyType',
            headerName: 'Стратегия',
            width: 80,
        },
        {
            field: 'lastPrice',
            headerName: 'Цена',
            description: 'Цена последней минутной свечи',
            width: 80,
            type: 'number',
        },
        {
            field: 'createDate',
            headerName: 'Дата',
            width: 170,
            type: 'dateTime',
        },
        {
            field: 'buyPrice',
            headerName: 'Покупка 38% (₽)',
            width: 160,
            type: 'number',
        },
        {
            field: 'stopLose',
            headerName: 'Stop lose (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' 🛑' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)}%)${time}`;
            },
        },
        {
            field: 'target1percent',
            headerName: 'Цель 1% (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' ✅' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)}%)${time}`;
            },
        },
        {
            field: 'target1000',
            headerName: '100.00% (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' ✅' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)}%)${time}`;
            },
        },
        {
            field: 'target1618',
            headerName: '161.80% (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' ✅' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)}%)${time}`;
            },
        },
        {
            field: 'target2618',
            headerName: 'Цель 261,8% (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' ✅' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)}%)${time}`;
            },
        },
        {
            field: 'target3618',
            headerName: 'Цель 361,8% (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' ✅' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)}%)${time}`;
            },
        },
        {
            field: 'target4236',
            headerName: '423.60% (₽)',
            width: 180,
            type: 'string',
            valueGetter: (target: PriceTarget, idea: TradeIdeaModel) => {
                if (!target || target?.price === -1) {
                    return '-';
                }
                const status = target.is_executed ? ' ✅' : '';
                const time = target.time ? ` | дата: ${getFormattedDate(target.time)}` : '';
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                return `${target.price}${status} (${getPercentStr(idea.buyPrice, target.price, targetMode)} %)${time}`;
            },
        },
    ];

    const rows = items.filter(filterItemsToRender);

    const showModal = !!action;

    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InstrumentsAutocomplete onChange={handleTickerChange} />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-select-small-label">Статус</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={activeStatus}
                    label="Статус"
                    onChange={handleActiveStatusChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value="1">Активна</MenuItem>
                    <MenuItem value="2">Не активна</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-select-small-label">Тренд</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={ideaType as string}
                    label="Тренд"
                    onChange={handleTrendDirectionChange}
                >
                    <MenuItem value={IdeaTypeItems.All}>Все</MenuItem>
                    <MenuItem value={IdeaTypeItems.Long}>Лонг</MenuItem>
                    <MenuItem value={IdeaTypeItems.Short}>Шорт</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="select-strategy-label">Стратегия</InputLabel>
                <Select
                    labelId="select-strategy-label"
                    id="select-strategy"
                    value={strategyType as string}
                    label="Стратегия"
                    onChange={handleStrategyChange}
                >
                    <MenuItem value={IStrategyItems.All}>Все</MenuItem>
                    <MenuItem value={IStrategyItems.Basic}>Базовая</MenuItem>
                    <MenuItem value={IStrategyItems.Rebound}>Разворот</MenuItem>
                    <MenuItem value={IStrategyItems.BigCandle}>BigCandle</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Тайм фрейм</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={timeFrame}
                    label="Тайм фрейм"
                    onChange={handleTimeFrameChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={TimeFrame.OneHour}>1 час</MenuItem>
                    <MenuItem value={TimeFrame.FourHour}>4 часа</MenuItem>
                    <MenuItem value={TimeFrame.OneDay}>1 день</MenuItem>
                    <MenuItem value={TimeFrame.OneWeek}>1 неделя</MenuItem>
                    <MenuItem value={TimeFrame.OneMonth}>1 месяц</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label">Эшелон Мосбиржи</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={moexLevel}
                    label="Эшелон Мосбиржи"
                    onChange={handleMoexLevelChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'0'}>Расписки</MenuItem>
                </Select>
            </FormControl>

            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // target1percent: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 50 },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: 'createDate',
                                sort: 'desc',
                            },
                        ],
                    },
                }}
                pageSizeOptions={[25, 50, 100]}
            />

            <ConfirmationDialog
                dialogTitle={action?.title || ''}
                handleCloseModal={handleCloseModal}
                showModalDialog={showModal}
                onSubmit={action?.onSubmit || noop}
                submitButtonText="Деактивировать"
                submitButtonColor={'error'}
            />
        </>
    );
}
