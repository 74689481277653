export enum TrendDirection {
    Up = 'uptrend',
    Down = 'downtrend',
}

export enum IdeaType {
    Short = 'short',
    Long = 'long',
}

export enum IStrategyItems {
    All = 'All',
    Basic = 'basic',
    Rebound = 'rebound',
    BigCandle = 'big_candle',
}

export enum ShareClassCode {
    SPBEQRU = 'SPBEQRU',
    SPBHKEX = 'SPBHKEX',
    SPBRU = 'SPBRU',
    SPBXM = 'SPBXM',
    TQBR = 'TQBR',
    TQPI = 'TQPI',
}

export enum TimeFrame {
    OneMinute = 'one_minute',
    FourHour = 'four_hour',
    OneHour = 'one_hour',
    OneDay = 'one_day',
    OneWeek = 'one_week',
    OneMonth = 'one_month',
}

export const TimeFrameValues = Object.values(TimeFrame);
